.container {
    width: 100%;
    margin-top: 40px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media(min-width: 851px) {
        display: none;
    }

    .link {
        margin-top: 50px;
        background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
        border-radius: 32px;
        box-shadow: 0 6px 19px 0 rgba(0,0,0,.24);
        width: 142px;
        max-width: 100%;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 1.2rem;
        border: none;
        cursor: pointer;
    }
}