.boxItem {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 10px 10px 10px;
    &:not(:last-of-type) {
        border-bottom: 1px dashed #3AAC7F;
    }
    &::before {
        content:'';
        position: absolute;
        top: 4px;
        left: 0;
        background-image: url(../../../images/fly-elements.png);
        background-size: cover;
        width: 108.84px;
        height: 31.64px;
    }

    .imgContainer {
        width: 80px;
        height: 80px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
    .textContainer {
        color: #5A5A5A;
        font-family: Nunito;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.05px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        max-width: 205px;
        span {
            color: #5A5A5A;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.05px;
            line-height: 15px;
            text-align: left;
        }
    }
}