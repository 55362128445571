.boxContainer {
    width: 330px;
    max-width: 100%;
    height: 138px;
    background-color: #fff;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    margin-bottom: 60px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-image: url(../../images/drop-down.svg);
        background-size: cover;
        top: 25px;
        left: 115px;
    }

    select{
        outline: none;
        min-width: 75px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.71px;
        line-height: 24px;
        position: relative;
        background: transparent;
    }
    .transactionsContainer {
        display: flex; 
        justify-content: space-between;

        .divisaContainer {
            width: 160px;
            margin-top: 4px;
            display: flex;
            align-items: center;
            .divisaFlag {
                width: 38px;
                height: 26px;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
            .divisaText {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                color: #343537;
                font-family: 'Quicksand', sans-serif;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.71px;
                line-height: 24px;
                text-align: left;
                position: relative;
                span {
                    color: #343537;
                    font-family: 'Quicksand', sans-serif;
                    font-size: 8px;
                    font-weight: 400;
                    letter-spacing: 0.29px;
                    line-height: 10px;
                    text-align: left;
                }
            }
        }
        .transaccion {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #343537;
            font-size: .8rem;
            font-weight: 300;
            font-family: 'Quicksand', sans-serif;
            span {
                color: #3AAC7F;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: -0.1px;
                line-height: 24px;
                text-align: center;
            }
        }
    }

    .secure {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .mode {
            svg {
                width: 9px;
                height: 9px;
                margin-right: 4px;
            }
            width: 100px;
            color: #637381;
            font-family: 'Quicksand', sans-serif;
            font-size: 9px;
            font-weight: 400;
            line-height: 11px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;
        }
    }
    .linkTo {
        position: absolute;
        width: 100%;
        height: 39px;
        bottom: 0;
        left: 0;
        background-color: #3F60FB;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
        box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.25);
        border: none;
        cursor: pointer;
    }

    @media(max-width: 889px) {
        display: none;
    }
}