.cardContainer {
    display: flex;
    flex-direction: column;
    @media(max-width: 730px) {
        align-items: center;
        margin-top: 30px;
    }
    .imgContainer {
        width: 130px;
        height: 130px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    p {
        margin-top: 14px;
        max-width: 143px;
        color: #5A5A5A;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.05px;
        line-height: 15px;
        text-align: center;
    }
}