.section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .textHeader {
        width: 324px;
        max-width: 100%;
        h2 {
            color: #5A5A5A;
            font-size: 2.4rem;
            font-weight: 400;
            letter-spacing: 0.28px;
            width: 324px;
            max-width: 100%;
            text-align: left;
            margin-bottom: 10px;
        }
        p {
            color: #5A5A5A;
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: 0.15px;
            line-height: 18px;
            width: 288px;
            text-align: left;
        }
        margin-bottom: 60px;
    
        
    }
    @media (max-width: 730px) {
        flex-direction: column;
        align-items: center;
    }
    @media(min-width: 890px) {
        display: none;
    }
}