.container {
    width: 967px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-top: 60px;
    flex-direction: column;
    padding: 20px;
    h2 {
        color: #5A5A5A;
        font-size: 31px;
        font-weight: 500;
        letter-spacing: 0.11px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 30px;
        @media(max-width: 730px) {
            font-size: 19px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .flagsContainer {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            @media(max-width: 1100px) {
                flex-direction: column;
                align-items: center;
            }
            span {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                width: 48%;
                @media(max-width: 1100px) {
                    width: 100%;
                }
            }
            
            img {
                width: 47px;
                height: 31px;
            }
        }

        .items {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 70px;
            @media(max-width: 730px) {
                order: -1;
                flex-direction: column;
                margin-top: unset;
            }
            width: 700px;
            max-width: 100%;
        }
    }
}