.container {
    width: 100%;
    height: 130px;
    background-color:white;

    .nav {
        height: 100%;
        width: 100%;
        max-width: 1340px;
        padding: 10px 80px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 889px) {
            padding: 10px;
        }
        .logoContainer {
            max-width: 289.66px;
            img {
                width: 100%;
            }
            @media (max-width: 889px) {
                max-width: 200px;
                margin: 0 auto;
            }
        }

        .navItems {
            display: flex;
            list-style-type: none;
            align-items: center;

            li {
                margin-left: 40px;

                @media (max-width:889px) {
                    &:nth-child(1),
                    &:nth-child(2) {
                        display: none;
                    }
                    margin: 0;
                    &:nth-child(3) {
                        width: 35px;
                        height: 35px;
                    }
                }
                .phoneNumber {
                    svg {
                        width: 15px;
                        height: 17px;
                        margin-right: 10px;
                    }
                    display: flex;
                    align-items: center;
                    color: #5E5F60;
                    font-size: 1.6rem;
                    font-weight: 400;
                    letter-spacing: 0.57px;
                    line-height: 19px;
                    text-align: center;
                }
                .login {
                    svg {
                        width: 8.33px;
                        height: 9.33px;
                        margin-right: 10px;
                    }
                    display: flex;
                    align-items: center;
                    background-color: #4A90E2;
                    border-radius: 100px;
                    padding: 10px 20px;
                    color: #FFFFFF;
                    letter-spacing: 0.6px;
                    font-size: 9.62px;
                    font-weight: 400;
                    line-height: 12px;
                    text-align: center;
                    text-transform: uppercase;
                    border: none;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                        background-color: rgb(56, 109, 170);
                    }
                }

                .loginMobile {
                    border: none;
                    @media(min-width: 890px) {
                        display: none;
                    }
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.phoneNumberMobile {
    @media (min-width: 890px) {
        display: none;
    }
    width: 100%;
    height: 40px;
    svg {
        width: 15px;
        height: 17px;
        margin-right: 10px;
        g g g g path {
            fill: rgba(255, 255, 255, .7);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5E5F60;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.57px;
    line-height: 19px;
    text-align: center;
    color: #F8F9FB;
    text-decoration: none;
}