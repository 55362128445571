.container {
    @media(min-width: 731px) {
        display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    h2 {
        color: #5A5A5A;
        font-size: 1.8rem;
    }
    .itemsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #D0D0D0;
    }
}