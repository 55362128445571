.suc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    max-width: 300px;
    position: relative;
    margin-left: 30px;
    &::before {
        content:'';
        position: absolute;
        height: 24.6px;
        width: 19.68px;
        background-image: url(../../../images/pin.png);
        background-size: cover;
        top: 8px;
        left: -30px;
    }
    .sucu {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        font-weight: 700;
        line-height: 23px;
        width: 268px;
        max-width: 100%;
        text-align: left;
    }

    .address {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        width: 300px;
        text-align: left;
    }

    .phone {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        width: 300px;
        text-align: left;
        span {
            font-weight: 700;
        }
    }
}