.container {
    width: 193px;
    height: 196px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    &::before {
        content:'';
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../../../images/fly-elements.png);
        background-size: cover;
        width: 108.84px;
        height: 31.64px;

    }
    .imgContainer {
        width: 123px;
        height: 123px;
        overflow: hidden;
        border-radius: 50%;
        border: 8px solid rgba(84, 218, 161, 0.5);
        img {
            width: 100%;
        }
    }

    .description {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 300;
        color: #5a5a5a;
        margin-top: 14px;
    }
}