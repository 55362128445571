.header {
    position: relative;
    background-image: url(../../images/img-top.jpg);
    height: 80vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    @media(max-width: 889px) {
        height: 280px;
        width: 100%;
    }

    @media (max-width: 600px) {
        height: 174px;
        width: 100%;
    }

    .down {
        position: absolute;
        bottom: -33px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        @media(max-width: 889px) {
            display: none;
        }
    }

    .textHeader {
        h2 {
            color: #FFFFFF;
            font-size: 2.4rem;
            font-weight: 400;
            letter-spacing: 0.28px;
            width: 324px;
            max-width: 100%;
            text-align: left;
            margin-bottom: 10px;
        }
        p {
            color: #FFFFFF;
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: 0.15px;
            line-height: 18px;
            width: 288px;
            text-align: left;
        }
        margin-bottom: 60px;

        @media(max-width: 889px) {
            display: none;
        }
    }
}