.container {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    h2 {
        
        color: #5A5A5A;
        font-size: 31px;
        font-weight: 500;
        letter-spacing: 0.11px;
        line-height: 35px;
        width: 384px;
        max-width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        @media(max-width: 730px) {
            font-size: 19px;
        }
    }

    .cards {
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        @media(max-width: 730px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    .link {
        margin-top: 50px;
        background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
        border-radius: 32px;
        box-shadow: 0 6px 19px 0 rgba(0,0,0,.24);
        width: 142px;
        max-width: 100%;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 1.2rem;
        border: none;
        cursor: pointer;
    }
}