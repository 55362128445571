*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  scroll-behavior: smooth;
}
body {
  background-color: #fff;
}

a {
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.gMap {
  position: relative;
  @media(max-width: 730px) {
    display: none;
  }
}

.gm-style-mtc,
.gm-control-active,
.gm-svpc,
.gmnoprint,
.gm-style-cc {
  display: none;
}


.modal-success,
.modal-error,
.modal-info {
  .swal2-actions {
    button {
      background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
      border-radius: 32px;
      box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.24);
      width: 112px;
      max-width: 100%;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      font-size: 1.2rem;
      border: none;
      cursor: pointer;
    }
    .swal2-confirm.swal2-styled {
      background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
      border-radius: 32px;
      box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.24);
      width: 112px;
      max-width: 100%;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      font-size: 1.2rem;
      border: none;
      cursor: pointer;

      &:hover {
        background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
      }
    }
  } 
}