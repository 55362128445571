.section {
    padding: 80px 20px;
    background-color: #F4F6F8;
    position: relative;
    display: flex;
    flex-direction: column;
    @media(max-width: 730px) {
        background-color: #fff;    
    }

    .formContainer {
        flex-direction: column;
        width: 503px;
        max-width: 100%;
        height: 633px;
        background-color: #FFFFFF;
        margin: 0 auto;

        .formHeader {
            margin: 0 auto;
            width: 267px;
            max-width: 100%;
            color: rgba(0, 0, 0, 0.54);
            font-family: Nunito;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;

            img {
                display: block;
                width: 137px;
                height: 63px;
                -webkit-transform: translate(50%);
                transform: translate(50%);
                margin: 21px 0px 16px 0px;
            }
        }

        .formBody {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            flex-direction: column;

            .formInput {
                border: none;
                background-color: #F8F9FB;
                color: rgba(0, 0, 0, 0.54);
                font-family: Nunito;
                font-size: 12px;
                font-weight: 300;
                line-height: 21px;
                padding-left: 17px;
                width: 418px;
                max-width: 100%;
                height: 45px;
                text-align: left;
                margin-top: 11px;
                border-bottom: 1px solid #54DAA1;
            }

            .formTextBox {
                height: 183px;
                text-align: start;
                resize: none;
            }

            button {
                display: flex;
                text-align: center;
                margin: 0 auto;
                background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
                border-radius: 32px;
                box-shadow: 0 6px 19px 0 rgba(0,0,0,.24);
                width: 142px;
                height: 39px;
                border: none;
                color: #FFFFFF;
                font-family: Nunito;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: -0.21px;
                line-height: 14px;
                padding-left: 54px;
                margin-top: 24px;
                transition: .2s ease;
                cursor: pointer;
                &:disabled {
                    
                    background-image: linear-gradient(180deg, #fff 0%, rgba(0,0,0, 0.25) 100%);
                    color: #5a5a5a;
                    cursor: not-allowed;
                }
            }
        }
    }

    .formFooter {
        margin: 0 auto;
        text-align: center;
        flex-direction: column;
        width: 100%;
        margin-top: 39px;
        color: #5A5A5A;
        font-family: 'Quicksand', sans-serif;
        font-size: 10px;
        font-weight: 300;
        line-height: 15px;

        .copyright {
            color: #8F9EA6;
            font-family: Helvetica;
            font-size: 9px;
            font-weight: 400;
            line-height: 11px;
            width: 288px;
            text-align: center;
            margin: 0 auto;
            margin-top: 30px;
        } 
    }

    .socials {
        display: flex;
        margin: 0 auto;
        margin-top: 16px;
        a {
            img {
                width: 25px;
                margin: 5px;
            }
        }
    }
}