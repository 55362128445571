.container {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 412px;
        max-width: 100%;
        @media(max-width: 730px) {
            align-items: flex-start;
            width: 142px;
            max-width: 100%;
        }
        h2 {
            color: #5A5A5A;
            font-size: 31px;
            font-weight: 500;
            letter-spacing: 0.11px;
            line-height: 35px;
            width: 281px;
            max-width: 100%;
            text-align: center;
            @media(max-width: 730px) {
                text-align: left;
                font-size: 19px;
                line-height: 23px;
            }
        }

        p {
            margin-top: 23px;
            color: #5A5A5A;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.05px;
            line-height: 21px;
            width: 311px;
            max-width: 100%;
            text-align: center;
            @media(max-width: 730px) {
                text-align: left;
            }
        }

        .linksContainer {
            margin-top: 38px;
            display: flex;
            width: 412px;
            max-width: 100%;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 730px) {
                flex-direction: column;
            }

            .playstore {
                position: relative;

                &::before {
                    position: absolute;
                    content:'';
                    background-image: url(../../images/playstore.png);
                    background-size: cover;
                    width: 17.36px;
                    height: 20px;
                    left: 40px;
                    top: 50%;
                    transform: translateY(-50%);
                    @media(max-width: 730px) {
                        left: 20px;
                    }
                }
            }

            .applestore {
                position: relative;

                &::before {
                    position: absolute;
                    content:'';
                    background-image: url(../../images/apple-logo.svg);
                    background-size: cover;
                    width: 15.83px;
                    height: 20px;
                    left: 40px;
                    top: 8px;
                    @media(max-width: 730px) {
                        left: 30px;
                    }
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 1.2rem;
                border-radius: 32px;
                font-weight: 700;
                box-shadow: 0 6px 19px 0 rgba(0,0,0,.2) ;
                width: 200px;
                max-width: 100%;
                height: 39px;
                background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
                &:hover {
                    background-image: linear-gradient(180deg, #7399FD 20%, #3F60FB 100%);
                }
                @media(max-width: 730px) {
                    margin-top: 30px;
                }
            }
        }
    }

    .imgContainer {
        width: 230.57px;
        height: 410px;
        overflow: hidden;
        margin-top: 60px;
        img {
            width: 100%;
        }
    }
}