.box {
    //184 ancho 211 alto
    height: 211px;
    width: 184px;
    background-image: linear-gradient(180deg, #FFFFFF 10%, #F4F4F4 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 31.64px;
        width: 108.84px;
        background-image: url(../../../images/fly-elements.png);
        background-size: cover;
        background-position: center;

    }

    img {
        margin: auto;
    }
    .description {
        color: #5A5A5A;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.05px;
        line-height: 15px;
        text-align: center;
        width: 162px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 14px;
        border-top: 1px dashed #3AAC7F;
        span {
            width: 185px;
            color: #5A5A5A;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.05px;
            line-height: 15px;
            text-align: center;
            margin: 10px 0 30px;
            padding: 0 2px;
        }
    }
}